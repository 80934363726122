import { Components, Theme } from "@mui/material"

export const MuiTypography: Components<Theme>["MuiTypography"] = {
  variants: [
    {
      props: { variant: "body3" },
      style: ({ theme }) => ({
        ...theme.typography.caption,
        ...body3,
      }),
    },
    {
      props: { color: "secondary" },
      style: ({ theme }) => ({
        color: theme.palette.secondary.main,
      }),
    },
    {
      props: { color: "tertiary" },
      style: ({ theme }) => ({
        color: theme.palette.tertiary.main,
      }),
    },
    {
      props: { color: "rogerPrimary" },
      style: ({ theme }) => ({
        color: theme.palette.rogerPrimary.main,
      }),
    },
  ],
}

export const body3 = {
  fontSize: 10,
  lineHeight: 1.7,
}

declare module "@mui/material/Typography" {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsColorOverrides {
    rogerPrimary: true
    tertiary: true
  }
}
