import { Components, Theme } from "@mui/material"

export const MuiButton: Components<Theme>["MuiButton"] = {
  defaultProps: {
    size: "small",
    variant: "contained",
  },
  styleOverrides: {
    root: {
      borderRadius: 4,
      paddingTop: 4,
      paddingBottom: 4,
      textTransform: "none",
    },
    sizeMedium: {
      borderRadius: 8,
    },
  },
  variants: [
    {
      props: { variant: "secondary" },
      style: ({ theme }) => ({
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.tertiary.border}`,
        borderRadius: 4,
        padding: "4px 8px",
        fontSize: theme.typography.pxToRem(13),
        ":active": {
          backgroundColor: theme.palette.tertiary.pressed,
        },
        "&:hover": {
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
    {
      props: { color: "primary", variant: "contained" },
      style: ({ theme }) => ({
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.blue.main,
        ":active": {
          backgroundColor: theme.palette.blueLight.main,
        },
        "&:hover": {
          backgroundColor: theme.palette.blueDark.main,
        },
        "&.MuiTouchRipple-child": {
          backgroundColor: theme.palette.blue.light,
        },
      }),
    },
    {
      props: { color: "primary", variant: "text", disabled: true },
      style: ({ theme }) => ({
        color: theme.palette.blue.disabled + " !important",
      }),
    },
    {
      props: { color: "primary", variant: "text" },
      style: ({ theme }) => ({
        color: theme.palette.blue.main,
        ":active": {
          backgroundColor: theme.palette.blue.pressed,
        },
        "&:hover": {
          color: theme.palette.blue.dark,
          backgroundColor: theme.palette.blue.hover + " !important",
        },
      }),
    },
    {
      props: { color: "tertiary", variant: "text" },
      style: ({ theme }) => ({
        color: theme.palette.tertiary.main,
        ":active": {
          backgroundColor: theme.palette.tertiary.pressed,
        },
        "&:hover": {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
    {
      props: { color: "charcoal", variant: "text" },
      style: ({ theme }) => ({
        color: theme.palette.charcoalSecondary.main,
        ":active": {
          backgroundColor: theme.palette.charcoal.pressed,
        },
        "&:hover": {
          backgroundColor: theme.palette.charcoal.hover,
        },
      }),
    },
    {
      props: { color: "inherit", variant: "text" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.tertiary.hover,
        },
        ":active": {
          backgroundColor: theme.palette.tertiary.pressed,
        },
      }),
    },
    {
      props: { color: "tertiary", variant: "text" },
      style: ({ theme }) => ({
        color: theme.palette.blue.bright,
        backgroundColor: "transparent !important",
        ":active": {
          backgroundColor: theme.palette.darkAction.pressed,
        },
        "&:hover": {
          backgroundColor: theme.palette.darkAction.buttonHover + " !important",
        },
      }),
    },
    {
      props: { color: "rogerPrimary" },
      style: ({ theme }) => ({
        color: "#fff",
        backgroundColor: theme.palette.rogerPrimary.main,
        ":active": {
          backgroundColor: theme.palette.rogerPrimary.main,
        },
        "&:hover": {
          backgroundColor: theme.palette.rogerPrimary.main,
        },
      }),
    },
    {
      props: { color: "blueDark", variant: "text" },
      style: ({ theme }) => ({
        color: theme.palette.blue.bright,
        ":active": {
          backgroundColor: "#FFFFFF52",
        },
        "&:hover": {
          backgroundColor: "#FFFFFF33",
        },
      }),
    },
    {
      props: { variant: "text" },
      style: ({ theme }) => ({
        padding: theme.spacing(1),
        borderRadius: 4,
        "&:hover": {
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
  ],
}

declare module "@mui/material/Button" {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    secondary: true
  }
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsColorOverrides {
    rogerPrimary: true
    secondary: true
    concrete: true
    sand: true
    charcoal: true
    tertiary: true
    green: true
    blueDark: true
    blue: true
  }
}
