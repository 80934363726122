import { Components, Theme } from "@mui/material"

export const MuiListItemButton: Components<Theme>["MuiListItemButton"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.rogerPrimary.main,
      "&.MuiMenuItem-root": { px: 2 },
      "&.Mui-selected": {
        backgroundColor: theme.palette.tertiary.selected,
        ":hover": {
          backgroundColor: theme.palette.tertiary.selected,
        },
      },
      ":hover": {
        backgroundColor: theme.palette.tertiary.hover,
      },
      transition: theme.transitions.create("background-color"),
    }),
  },
}
