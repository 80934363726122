import { trpc } from "utils/trpc"

const useMeData = () => {
  const utils = trpc.useContext()
  const data = utils.me.get.getData()

  return {
    myUserId: data?.me.id ?? "",
    myName: data?.me.name ?? "",
    myEmail: data?.me.email ?? "",
    myAvatarImg: data?.me.image ?? undefined,
    myAvatarColor: data?.me.avatarColor ?? undefined,
    companyId: data?.me.companyId ?? "",
    myAccountId: data?.account?.id ?? "",
    defaultFocusedInbox: data?.me.defaultFocusedInbox ?? undefined,
    validEmailAccount: data?.validEmailAccount ?? undefined,
    provider: data?.account?.provider ?? undefined,
    inboxType: data?.me.inboxType ?? undefined,
    useBetaFeatures: data?.me.useBetaFeatures ?? undefined,
    isAdmin: data?.me.masqueradeAccess ?? undefined,
    procoreUserId: data?.me.procoreUserId ?? undefined,
  }
}

export default useMeData
