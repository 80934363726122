import { trpc } from "frontend/trpc/trpc"
import getConfig from "next/config"
import React from "react"
import OneSignal from "react-onesignal"

const NOTIFICATION_UPDATE_INTERVAL = 60 * 1000

export const useOneSignal = () => {
  const [initialized, setInitialized] = React.useState(false)
  const { mutateAsync } = trpc.me.updateNotifications.useMutation()

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      const { publicRuntimeConfig } = getConfig() || {}
      const localWindow = window as any

      if (!localWindow.OneSignal) {
        OneSignal.init({
          appId: publicRuntimeConfig?.ONE_SIGNAL_APP_ID,
          allowLocalhostAsSecureOrigin: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const id = setInterval(async () => {
      const userId = await OneSignal.getUserId()
      const enabled = await OneSignal.isPushNotificationsEnabled()
      if (userId) {
        await mutateAsync({ foreignId: userId, enabled })
      }
    }, NOTIFICATION_UPDATE_INTERVAL)

    return () => clearTimeout(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
