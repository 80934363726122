import useMeData from "frontend/hooks/useMeData"
import React from "react"

export let amplitude: any
const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY

const useAmplitudeInit = () => {
  const { myEmail } = useMeData()

  React.useEffect(() => {
    const initAmplitude = async () => {
      if (myEmail) {
        amplitude = await import("@amplitude/analytics-browser")
        amplitude.init(AMPLITUDE_API_KEY, myEmail, {
          logLevel: amplitude.Types.LogLevel.Warn,
          defaultTracking: {
            sessions: true,
          },
        })
      }
    }

    if (myEmail) {
      initAmplitude()
    }
  }, [myEmail])
}

export const useAmplitudeTrack = () => {
  const { myName, myEmail, myUserId, companyId } = useMeData()

  return React.useCallback(
    (eventName: string, meta: any) => {
      if (amplitude) {
        amplitude.track(
          eventName,
          {
            user: { email: myEmail, id: myUserId, name: myName, companyId },
            meta,
          },
          {
            user_id: myEmail,
          }
        )
      }
    },
    [myName, myEmail, myUserId, companyId]
  )
}

export default useAmplitudeInit
