import { Components, Theme } from "@mui/material"

export const MuiIconButton: Components<Theme>["MuiIconButton"] = {
  defaultProps: {
    size: "small",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 2,
      borderRadius: theme.shape.borderRadius,
    }),
  },
  variants: [
    {
      props: { color: "light" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: `rgba(255, 255, 255,  ${
            theme.palette.action.hoverOpacity * 2
          })`,
        },
      }),
    },
    {
      props: { color: "default" },
      style: ({ theme }) => ({
        color: theme.palette.secondary.main,
        ":active": {
          backgroundColor: theme.palette.tertiary.pressed,
        },
        "&:hover": {
          color: theme.palette.rogerPrimary.main,
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
    {
      props: { color: "tertiary" },
      style: ({ theme }) => ({
        color: theme.palette.secondary.main,
        ":active": {
          backgroundColor: theme.palette.tertiary.pressed,
        },
        "&:hover": {
          color: theme.palette.rogerPrimary.main,
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
    {
      props: { color: "rogerPrimary" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.tertiary.hover,
        },
      }),
    },
    {
      props: { color: "blue" },
      style: ({ theme }) => ({
        color: theme.palette.blue.main,
        ":active": {
          backgroundColor: theme.palette.blue.pressed,
        },
        "&:hover": {
          color: theme.palette.blue.dark,
          backgroundColor: theme.palette.blue.hover,
        },
      }),
    },
  ],
}

declare module "@mui/material/IconButton" {
  // eslint-disable-next-line no-unused-vars
  interface IconButtonPropsColorOverrides {
    light: true
    rogerPrimary: true
    secondary: true
    concrete: true
    sand: true
    charcoal: true
    tertiary: true
    green: true
    blue: true
    blueDark: true
  }
}
