import { TypographyOptions } from "@mui/material/styles/createTypography"

export const typography: TypographyOptions = {
  h1: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.33,
  },
  h2: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.33,
  },
  h3: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.4,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: 1.4,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
  },
  h7NavSubsection: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.17,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 0.25,
  },
  notification1: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.17,
  },
  body2: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.66,
    letterSpacing: 0.4,
  },
  body3: {
    fontSize: 10,
    lineHeight: 1.7,
  },
  chip1: {
    fontSize: 13,
    lineHeight: 1.66,
    letterSpacing: 0,
  },
  chip2: {
    fontSize: 12,
    letterSpacing: 0,
  },
  fontFamily: "Outfit",
  button: {
    lineHeight: 1.25,
  },
  inputText: {
    fontFamily: "Roboto",
  },
}
