namespace BoxShadows {
  export const drop1 = {
    filter: "drop-shadow(0px 2px 4px rgba(30, 38, 57, 0.14))",
  }
  export const drop2 = {
    filter:
      "drop-shadow(0px 2px 4px rgba(30, 38, 57, 0.14)) drop-shadow(0px 1px 8px rgba(30, 38, 57, 0.12));",
  }
  export const drop3 = {
    filter:
      "drop-shadow(0px 4px 5px rgba(30, 38, 57, 0.14)) drop-shadow(0px 1px 10px rgba(30, 38, 57, 0.12));",
    boxShadow: "0px 2px 4px -1px rgba(30, 38, 57, 0.2);",
  }
  export const drop4 = {
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(30, 38, 57, 0.2), 0px 1px 10px rgba(30, 38, 57, 0.1);",
  }
  export const dropCard = {
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);",
  }
}

export default BoxShadows
