import { Components, Theme } from "@mui/material"

export const MuiTab: Components<Theme>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      opacity: 1,
      textTransform: "none",
      padding: theme.spacing(0, 0, 1, 1),
      color: theme.palette.secondary.main,
      minHeight: 32,
      "&:not(:last-child)": {
        marginRight: theme.spacing(3),
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& .tab-count": {
          color: theme.palette.secondary.main,
        },
      },
      "&.selected-link-tab": {
        color: theme.palette.rogerPrimary.main,
      },
      "&.selected": {
        color: theme.palette.rogerPrimary.main,
      },
    }),
  },
}
