import { Components, Theme } from "@mui/material"

export const MuiSvgIcon: Components<Theme>["MuiSvgIcon"] = {
  styleOverrides: {
    root: {
      fontSize: "1.25rem",
    },
    fontSizeInherit: {
      fontSize: "inherit",
    },
    fontSizeLarge: {
      fontSize: "2rem",
    },
    fontSizeSmall: {
      fontSize: "0.875rem",
    },
  },
}
