import { CheckBoxOutlined } from "@mui/icons-material"
import { Components, Theme } from "@mui/material"

export const MuiCheckbox: Components<Theme>["MuiCheckbox"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      color: theme.palette.secondary.main,
      ":active": {
        backgroundColor: theme.palette.tertiary.pressed,
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.tertiary.hover,
      },
    }),
  },
  variants: [
    {
      props: { color: "primary" },
      style: ({ theme }) => ({
        color: theme.palette.secondary.main,
        ":active": {
          backgroundColor: theme.palette.blue.pressed,
        },
        "&:hover": {
          color: theme.palette.blue.dark,
          backgroundColor: theme.palette.blue.hover,
        },
      }),
    },
  ],
  defaultProps: {
    checkedIcon: <CheckBoxOutlined />,
  },
}
