import { CacheProvider, EmotionCache } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/system"
import { LicenseInfo } from "@mui/x-license-pro"
import { initAmplitudeNode } from "@roger-build/common/dist/libraries/amplitude/amplitude-client"
import useAmplitudeInit from "frontend/hooks/useAmplitudeInit"
import { useOneSignal } from "frontend/hooks/useOneSignal"
import BoxShadows from "frontend/theme/box-shadows"
import theme from "frontend/theme/theme"
import createEmotionCache from "frontend/utils/create-emotion-cache"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import { NextPage } from "next"
import { SessionProvider } from "next-auth/react"
import type { AppProps } from "next/app"
import Head from "next/head"
import { SnackbarProvider } from "notistack"
import { ComponentType, ReactElement } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { trpc } from "utils/trpc"
import "./globals.css"

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_DATA_GRID_LICENSE_KEY || "")

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (_page: ReactElement) => ReactElement
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  emotionCache: EmotionCache
}

initAmplitudeNode()

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

function RogerWeb({
  Component,
  emotionCache,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  useOneSignal()
  useAmplitudeInit()

  return (
    <CacheProvider value={emotionCache || clientSideEmotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SessionProvider
          session={session}
          refetchInterval={5 * 60}
          refetchOnWindowFocus
        >
          <DndProvider backend={HTML5Backend}>
            <SnackbarProvider
              maxSnack={5}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              //@ts-ignore
              sx={{
                "& .SnackbarContent-root": {
                  color: "white",
                  backdropFilter: "blur(10px)",
                  bgcolor: "secondary.main",
                  ...BoxShadows.drop3,
                },
                "& .SnackbarItem-action": {
                  color: "#1976D2",
                },
                "& .MuiButton-root": {
                  boxShadow: "unset",
                  paddingTop: 2,
                  paddingBottom: 2,
                  ":hover": {
                    bgColor: theme.palette.tertiary.hover,
                  },
                },
              }}
            >
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/roger_32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/roger_16x16.png"
                />
                <meta name="msapplication-TileColor" content="#303030" />
                <meta name="theme-color" content="#303030" />
              </Head>
              {getLayout(<Component {...pageProps} />)}
            </SnackbarProvider>
          </DndProvider>
        </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

const LDProvider = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID ?? "",
  options: {
    streaming: true,
  },
})(RogerWeb as ComponentType)

export default trpc.withTRPC(LDProvider)
