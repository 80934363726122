import { InferQueryInput, TQuery } from "frontend/trpc/types"
import * as trpcUtils from "../../utils/trpc"

// import superjson from 'superjson';
// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = trpcUtils.trpc

export type QueryAndMaybeInput = QueryWithInput<TQuery> | TQuery

export type QueryWithInput<T extends TQuery> = [T, InferQueryInput<T>]
