/* eslint-disable no-unused-vars */
import { PaletteOptions } from "@mui/material"

export const hoverOpacity = 0.12
export const hover = `rgba(0, 0, 0, ${hoverOpacity})`

export const palette: PaletteOptions = {
  action: {
    hover,
    hoverOpacity,
  },
  light: {
    main: "#F5F5F5",
  },
  primary: {
    main: "#298FD6",
  },
  primaryLight: {
    main: "#1E1E1EE5",
  },
  rogerPrimary: {
    main: "#1E1E1E",
    hover: "#354464",
  },
  secondary: {
    main: "#1E1E1EB2",
  },
  tertiary: {
    main: "#1E1E1E61",
    hover: "#1E1E1E0A",
    selected: "#1E1E1E14",
    activated: "#1E1E1E1F",
    border: "#1E1E1E1F",
    pressed: "#1E1E1E29",
    deactivated: "#1E1E1E3D",
  },
  disabled: {
    main: "#1E26393D",
  },
  divider: "#1E26391F",
  border: {
    main: `rgba(0, 0, 0, ${hoverOpacity / 2})`,
  },
  concrete: {
    main: "#C0BBAF",
    hover: "#857E6F14",
    pressed: "#857E6F47",
    deactivated: "#C0BBAF66",
    border: "#C4BFB3",
    selected: "#A6A6A6",
  },
  concreteDark: {
    main: "#857E6F",
    hover: "#857E6F14",
    pressed: "#857E6F47",
    activated: "#857E6F29",
    deactivated: "#857E6F66",
  },
  sand: {
    main: "#E6E7DD",
    hover: "#1E1E1E0A",
  },
  sandLight: {
    main: "#F6F6F3",
  },
  stickyOrange: {
    main: "#FFC470",
    dark: "#FFB853",
  },
  stickyGreen: {
    main: "#79D297",
    dark: "#72C58E",
    hover: "#74CA91",
    selected: "#74CA91CC",
  },
  primaryBackground: {
    main: "#FFFFFF",
  },
  blue: {
    main: "#298FD6",
    hover: "#298FD614",
    selected: "#298FD61F",
    activated: "#298FD629",
    border: "#298FD666",
    pressed: "#298FD647",
    deactivated: "#298FD666",
    disabled: "#298FD666",
  },
  blueLight: {
    main: "#6EAFDD",
  },
  blueBright: {
    main: "#43A9EF",
  },
  blueDark: {
    main: "#106FB0",
  },
  fire: {
    main: "#C35722",
    hover: "#F6E6DE",
    selected: "#C357221F",
    activated: "#C3572229",
    pressed: "#C3572247",
    deactivated: "#C3572266",
  },
  green: {
    main: "#279627",
    hover: "#27962714",
    selected: "#2796271F",
    activated: "#27962729",
    pressed: "#27962747",
    dark: "#106A10",
    border: "#2E7D3266",
  },
  darkAction: {
    main: "#FFFFFF",
    hover: "#FFFFFF33",
    selected: "#FFFFFF3D",
    activated: "#FFFFFF47",
    pressed: "#FFFFFF52",
    disabled: "#FFFFFF61",
    buttonHover: "#FFFFFFB3",
  },
  darkSecondary: {
    main: "#FFFFFFBF",
  },
  stickyYellow: {
    main: "#FFC470",
    hover: "#FFB853",
    selected: "#FFB853CC",
  },
  rogerPatina: {
    main: "#A3C7A4",
  },
  rust: {
    main: "#934D2B",
  },
  steelBlue: {
    main: "#346180",
  },
  charcoal: {
    main: "#211F20",
    hover: "#1E1E1E0A",
    pressed: "#1E1E1E29",
  },
  charcoalSecondary: {
    main: "#211F20B2",
  },
  seafoamBright: {
    main: "#5CC7E9",
  },
}

declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    hover?: string
    pressed?: string
    bright?: string
    disabled?: string
    border?: string
    selected?: string
    activated?: string
    focus?: string
    buttonHover?: string
    deactivated?: string
  }
  interface PaletteColor {
    hover?: string
    pressed?: string
    bright?: string
    disabled?: string
    border?: string
    selected?: string
    activated?: string
    focus?: string
    buttonHover?: string
    deactivated?: string
  }
  interface Palette {
    light: Palette["primary"]
    concreteDark: Palette["primary"]
    rust: Palette["primary"]
    steelBlue: Palette["primary"]
    rogerPrimary: Palette["primary"]
    concrete: Palette["primary"]
    sandLight: Palette["primary"]
    sand: Palette["primary"]
    darkAction: Palette["primary"]
    darkSecondary: Palette["primary"]
    border: Palette["primary"]
    stickyOrange: Palette["primary"]
    stickyGreen: Palette["primary"]
    stickyYellow: Palette["primary"]
    tertiary: Palette["primary"]
    disabled: Palette["primary"]
    primaryBackground: Palette["primary"]
    primaryLight: Palette["primary"]
    blue: Palette["primary"]
    blueDark: Palette["primary"]
    blueLight: Palette["primary"]
    blueBright: Palette["primary"]
    green: Palette["primary"]
    fire: Palette["primary"]
    rogerPatina: Palette["primary"]
    charcoalSecondary: Palette["primary"]
    charcoal: Palette["primary"]
    seafoamBright: Palette["primary"]
  }

  interface PaletteOptions {
    light: PaletteOptions["primary"]
    rogerPrimary: PaletteOptions["primary"]
    concreteDark: PaletteOptions["primary"]
    rust: PaletteOptions["primary"]
    steelBlue: PaletteOptions["primary"]
    concrete: PaletteOptions["primary"]
    sandLight: PaletteOptions["primary"]
    sand: PaletteOptions["primary"]
    darkAction: PaletteOptions["primary"]
    darkSecondary: PaletteOptions["primary"]
    border: PaletteOptions["primary"]
    stickyOrange: PaletteOptions["primary"]
    stickyGreen: PaletteOptions["primary"]
    stickyYellow: PaletteOptions["primary"]
    tertiary: PaletteOptions["primary"]
    disabled: PaletteOptions["primary"]
    primaryBackground: PaletteOptions["primary"]
    blue: PaletteOptions["primary"]
    blueDark: PaletteOptions["primary"]
    blueLight: PaletteOptions["primary"]
    blueBright: PaletteOptions["primary"]
    green: PaletteOptions["primary"]
    primaryLight: PaletteOptions["primary"]
    fire: PaletteOptions["primary"]
    rogerPatina: PaletteOptions["primary"]
    charcoalSecondary: PaletteOptions["primary"]
    charcoal: PaletteOptions["primary"]
    seafoamBright: PaletteOptions["primary"]
  }
}

export const defaultTag = "#D2D4DB"

export const concrete = "#98A0AF"

export const pastels = {
  yellow: "#FFEBCA",
  pink: "#F8E1ED",
  gray: "#EBEBEB",
  green: "#DBEDDC",
  purple: "#EADDEF",
  orange: "#FADEC9",
  brown: "#EFE0D9",
  red: "#FEE2DE",
  lightGray: "#F1EFF0",
  blue: "#D1E3F0",
  vividGray: "#97A1B0",
  vividRed: "#CA0911",
  vividOrange: "#E8863D",
  vividYellow: "#F1BC40",
  vividGreen: "#609D4E",
  vividTeal: "#387583",
  vividPurple: "#5E439B",
  vividPink: "#A3426F",
  redMain: "#D32F2F",
  redLight: "#A7130547",
} as const

export const pastelKeys = Object.keys(pastels) as PastelColor[]

export type PastelColor = keyof typeof pastels

export const solids = {
  gray: concrete,
  red: "#CB0413",
  seafoam: "#C3D4D9",
  orange: "#E7873C",
  yellow: "#F2BB3F",
  green: "#5A9F4C",
  blue: "#377582",
  purple: "#5D439A",
  pink: "#A0436D",
  bronze: "#C5B59C",
}

export const solidKeys = Object.keys(solids) as SolidColor[]

export type SolidColor = keyof typeof solids
