import { Components, Theme } from "@mui/material"

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary.main,
      "&.MuiMenuItem-root": { px: 2 },
      "&.Mui-selected": { backgroundColor: theme.palette.tertiary.selected },
      ":hover": {
        backgroundColor: theme.palette.tertiary.hover,
      },
      transition: "background-color ease 0.2s",
    }),
  },
}
