import { Components, Theme } from "@mui/material"

export const MuiInput: Components<Theme>["MuiInput"] = {
  styleOverrides: {
    root: {
      ":hover:not(.Mui-disabled)::before": {
        borderBottom: "none !important",
      },
      "&::before": {
        borderBottom: "none !important",
      },
    },
  },
}
