import { Components, Theme } from "@mui/material"

export const MuiAlert: Components<Theme>["MuiAlert"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(1),
    }),
  },
}

declare module "@mui/material/Alert" {
  // eslint-disable-next-line no-unused-vars
  interface AlertPropsColorOverrides {
    secondary: true
    light: true
    rogerPrimary: true
    concrete: true
    sand: true
    tertiary: true
    darkAction: true
    border: true
    stickyOrange: true
    stickyGreen: true
    stickyYellow: true
    disabled: true
    primaryBackground: true
    blue: true
    green: true
  }
}
