import { Components, Theme } from "@mui/material"

export const MuiTabs: Components<Theme>["MuiTabs"] = {
  styleOverrides: {
    root: {
      minHeight: 0,
    },
    indicator: ({ theme }) => ({
      backgroundColor: theme.palette.rogerPrimary.main,
      height: 7,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
    }),
  },
}
