import { httpBatchLink } from "@trpc/client"
import { createTRPCNext } from "@trpc/next"
import superjson from "superjson"
import type { AppRouter } from "../backend/routers/_app"

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    return {
      transformer: superjson,
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           */
          url: `/api/trpc`,
          // You can pass any HTTP headers you wish here
          headers: () => {
            if (ctx?.req) {
              // on ssr, forward client's headers to the server
              return {
                ...ctx.req.headers,
                "x-ssr": "1",
              }
            }
            return {}
          },
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: { staleTime: 60, refetchOnWindowFocus: false },
        },
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})
