import { Components, Theme } from "@mui/material"

export const MuiFab: Components<Theme>["MuiFab"] = {
  styleOverrides: {
    root: {
      width: 36,
      height: 36,
    },
  },
}
