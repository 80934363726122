import { Components, Theme } from "@mui/material"

export const MuiButtonGroup: Components<Theme>["MuiButtonGroup"] = {
  defaultProps: {
    variant: "contained",
  },
  styleOverrides: {
    root: {
      borderRadius: 18,
      boxShadow: "none",
    },
    grouped: ({ theme }) => ({
      boxShadow: theme.shadows[1],
      "&:hover": {
        boxShadow: theme.shadows[3],
      },
      "&:not(:last-of-type)": {
        borderRight: "none",
        marginRight: 2,
      },
      "&:last-of-type": {
        padding: 0,
        minWidth: 36,
      },
    }),
  },
}
