import { Components, Theme } from "@mui/material"

export const MuiOutlinedInput: Components<Theme>["MuiOutlinedInput"] = {
  styleOverrides: {
    input: ({ theme }) => ({
      padding: theme.spacing(1, 2),
      height: "1.5em",
      lineHeight: "1.5em",
    }),
    inputSizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.5, 2),
      height: "24px",
      lineHeight: "24px",
      fontSize: "0.875rem",
    }),
  },
}
