import { Components, Theme } from "@mui/material"

export const MuiToggleButton: Components<Theme>["MuiToggleButton"] = {
  defaultProps: {
    size: "small",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      borderRadius: (theme.shape.borderRadius as number) * 3,
      color: theme.palette.text.primary,
      padding: "3px 9px",
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        color: "#FFF",
      },
    }),
  },
}
