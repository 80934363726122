import { Components, Theme } from "@mui/material"

export const MuiTextField: Components<Theme>["MuiTextField"] = {
  styleOverrides: {
    root: {
      fontFamily: "Roboto",
      "& .MuiInput-root": { fontFamily: "Roboto" },
      "& .MuiInputBase-root": {
        fontFamily: "Roboto",
        ":hover:not(.Mui-disabled)::before": {
          borderBottomStyle: "none",
        },
        "&::before": {
          borderBottomStyle: "none",
        },
      },
      "& .MuiInputBase-input": {
        "&::placeholder": {
          fontFamily: "Outfit",
        },
      },
    },
  },
}
