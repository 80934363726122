import { createTheme } from "@mui/material/styles"
import { MuiTypography } from "frontend/theme/MuiTypography"
import { MuiAlert } from "frontend/theme/mui-alert"
import { MuiAvatar } from "frontend/theme/mui-avatar"
import { MuiButton } from "frontend/theme/mui-button"
import { MuiButtonGroup } from "frontend/theme/mui-button-group"
import { MuiCheckbox } from "frontend/theme/mui-checkbox"
import { MuiDrawer } from "frontend/theme/mui-drawer"
import { MuiFab } from "frontend/theme/mui-fab"
import { MuiIconButton } from "frontend/theme/mui-icon-button"
import { MuiInput } from "frontend/theme/mui-input"
import { MuiListItemButton } from "frontend/theme/mui-list-item-button"
import { MuiMenuItem } from "frontend/theme/mui-menu-item"
import { MuiOutlinedInput } from "frontend/theme/mui-outlined-input"
import { MuiSvgIcon } from "frontend/theme/mui-svg-icon"
import { MuiTab } from "frontend/theme/mui-tab"
import { MuiTabs } from "frontend/theme/mui-tabs"
import { MuiToggleButton } from "frontend/theme/mui-toggle-button"
import { MuiToolbar } from "frontend/theme/mui-toolbar"
import { MuiTooltip } from "frontend/theme/mui-tooltip"
import { typography } from "frontend/theme/typography"
import { palette } from "frontend/utils/palette"
import { MuiTextField } from "./mui-text-field"

export const spacing = 4
export const borderRadius = 5

//top-left, top-right, bottom-right, bottom-left
export const getBorderRadius = (...mult: number[]): string =>
  mult.map((m) => `${m * borderRadius}px`).join(" ")

const theme = createTheme({
  spacing,
  shape: {
    borderRadius,
  },
  components: {
    MuiAvatar,
    MuiButton,
    MuiButtonGroup,
    MuiCheckbox,
    MuiFab,
    MuiIconButton,
    MuiInput,
    MuiTextField,
    MuiMenuItem,
    MuiOutlinedInput,
    MuiSvgIcon,
    MuiTab,
    MuiTabs,
    MuiToggleButton,
    MuiTypography,
    MuiAlert,
    MuiTooltip,
    MuiListItemButton,
    MuiToolbar,
    MuiDrawer,
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       backgroundColor: palette.rogerPrimary?.main,
    //       opacity: 0.2
    //     }
    //   }
    // }
  },
  palette,
  typography,
})

export default theme
